<template>
  <div class="onLine-list">
    <div
      class="list-box"
      :style="{ 'padding-bottom': dataList.length > 0 ? '58px' : '' }"
    >
      <div class="item1" v-for="item in dataList" :key="item.fk_resource_id">
        <data-item :item="item" />
      </div>
      <div
        class="item1"
        v-for="(item, index) in dataList.length > 0
          ? 4 - (dataList.length % 4)
          : 0"
        :key="index"
      ></div>
    </div>
  </div>
</template>

<script>
import dataItem from "./dataItem";
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    dataItem,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.onLine-list {
  width: 100%;
  padding-top: 10px;

  .list-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item1 {
      width: calc(25% - 12px);
    }
  }
}
</style>
