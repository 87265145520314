<template>
  <div class="item" @click="openDetail(item.fk_resource_id, item.url)">
    <div class="item-img-box">
      <img class="item-img" :src="item.img || kong" alt="" />
    </div>
    <div class="item-content">
      <div class="content-title line-1">
        {{ item.name }}
      </div>
      <div class="content-type">
        <div class="type-class">年级：{{ item.grade }}</div>
        <div class="type-line"></div>
        <div class="type-obj">学科：{{ item.subject }}</div>
      </div>
      <div class="content-bottom">
        <span>用户点赞数{{ item.fabulous_count }}</span>
        <span style="color: #1d2088"
          >文件资源{{ item.resource_file_count }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openDetail(id, url) {
      if (url) {
        window.open(url);
        return;
      }
      this.$router.push({
        name: "resourse_detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 12px;
  z-index: 10;
  position: relative;
  cursor: pointer;
  .item-img-box {
    width: 100%;
    height: 164px;
    position: relative;
    .item-img {
      height: 100%;
    }
    .live-tip {
      position: absolute;
      top: 0;
      right: 0;
      width: 54px;
      height: 24px;
      background: #1d2088;
      border-radius: 0px 10px 0px 10px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      font-weight: 400;
    }
  }
  .item-content {
    flex: 1;
    padding: 14px 18px;
    box-sizing: border-box;
    .content-title {
      flex: 1;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 14px;
    }
    .content-type {
      display: flex;
      align-items: center;
      font-size: 14px;

      font-weight: 400;
      color: #666666;
      margin-bottom: 18px;
      .type-line {
        width: 1px;
        height: 14px;
        background-color: #999;
        margin: 0 5px;
      }
    }
    .content-bottom-live {
      padding-top: 14px;
      border-top: 1px solid #ececec;
      font-size: 14px;

      font-weight: 400;
      color: #999999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content-bottom {
      padding-top: 14px;
      border-top: 1px solid #ececec;
      font-size: 14px;

      font-weight: 400;
      color: #1d2088;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;

      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
