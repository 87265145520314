<template>
  <div class="resourceSharing">
    <div class="content-box">
      <div class="search-box">
        <img
          class="search-img"
          src="@/assets/resourceSharing/banner1.png"
          alt=""
        />
        <div class="search">
          <div class="input-box">
            <el-input
              v-model="keywords"
              placeholder="请输入搜索关键字.."
              :clearable="true"
            ></el-input>
          </div>
          <div class="search-btn" @click="onSearch">搜索</div>
        </div>
      </div>
    </div>
    <div class="nav-box">
      <div class="nav-list">
        <div
          :class="['nav-item', searchForm.type == item.type ? 'active' : '']"
          v-for="(item, index) in navList"
          :key="index"
          @click="changNav(item.type, item.categoryType)"
        >
          <img
            class="item-icon"
            v-if="searchForm.type == item.type"
            :src="item.selecticon"
            alt=""
          />
          <img class="item-icon" v-else :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="onLine">
        <div class="title">资源分享</div>
        <div class="nav-box1">
          <template v-if="categoryList.length > 0">
            <div class="nav-type">
              <div class="type-title">类型：</div>
              <div class="type-list">
                <div
                  :class="[
                    'type-item',
                    item.fk_category_id == searchForm.fk_category_id
                      ? 'active'
                      : '',
                  ]"
                  @click="changCategory(item.fk_category_id)"
                  v-for="(item, index) in categoryList"
                  :key="index"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </template>
          <div class="nav-type" v-if="classList.length > 0">
            <div class="type-title">年级：</div>
            <div class="type-list">
              <div
                :class="[
                  'type-item',
                  item.fk_category_id == searchForm.fk_grade_id ? 'active' : '',
                ]"
                v-for="(item, index) in classList"
                :key="index"
                @click="changClass(item.fk_category_id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="nav-type" v-if="subjectList.length > 0">
            <div class="type-title">学科：</div>
            <div class="type-list">
              <div
                :class="[
                  'type-item',
                  item.fk_category_id == searchForm.fk_subject_id
                    ? 'active'
                    : '',
                ]"
                v-for="(item, index) in subjectList"
                :key="index"
                @click="changSubject(item.fk_category_id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div v-loading="dataListParams.loading">
          <template v-if="dataList.length > 0">
            <resource-sharing-list :dataList="dataList" />
          </template>
          <div class="kong" v-else>
            <el-empty
              image="https://eduapi.114kaili.com/Content/images/no_data.png"
              :image-size="303"
              description="暂无数据"
            ></el-empty>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            @current-change="handleCurrentChange"
            :background="true"
            @prev-click="handleCurrentChange"
            @next-click="handleCurrentChange"
            :page-size="dataListParams.pageSize"
            layout="total, prev, pager, next"
            :total="dataListParams.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resourceSharingList from "./components/resourceSharingList";
import tableMinxin from "@/mixins/tableMinxin.js";
import Api from "@/api";
export default {
  name: "resourse",
  mixins: [tableMinxin],
  components: {
    resourceSharingList,
  },
  data() {
    return {
      navList: [
        {
          name: "课程教学",
          type: 1,
          categoryType: 5,
          icon: require("@/assets/resourceSharing/ic_1.png"),
          selecticon: require("@/assets/resourceSharing/ic_1_fill.png"),
        },
        {
          name: "课件教学设计",
          type: 2,
          categoryType: 6,
          icon: require("@/assets/resourceSharing/ic_3.png"),
          selecticon: require("@/assets/resourceSharing/ic_3_fill.png"),
        },
        {
          name: "科研成果",
          type: 3,
          categoryType: 7,
          icon: require("@/assets/resourceSharing/ic_2.png"),
          selecticon: require("@/assets/resourceSharing/ic_2_fill.png"),
        },
        {
          name: "教学资源",
          type: 4,
          categoryType: 8,
          icon: require("@/assets/resourceSharing/ic_2.png"),
          selecticon: require("@/assets/resourceSharing/ic_2_fill.png"),
        },
      ],
      categoryList: [],
      classList: [],
      subjectList: [],
      keywords: "",
      searchForm: {
        type: 99,
        fk_category_id: 0,
        fk_grade_id: 0,
        fk_subject_id: 0,
        keywords: "",
      },
    };
  },
  mounted() {
    // Promise.all([
    //   Api.Category({
    //     type: 2,
    //   }),
    //   Api.Category({
    //     type: 3,
    //   }),
    // ]).then((res) => {
    //   this.classList = res[0].data;
    //   this.subjectList = res[1].data;
    // });
    this.getTitle(5);
    this.initPage();
  },
  methods: {
    initPage() {
      this._init_data("Resource", {
        ...this.searchForm,
      });
    },
    getCategory(type) {
      Api.Category({
        type: type,
      }).then((res) => {
        if (res.status == 1) {
          this.categoryList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changCategory(id) {
      this.searchForm.fk_category_id = id;
      this.initPage();
    },
    changClass(id) {
      this.searchForm.fk_grade_id = id;
      this.initPage();
    },
    onSearch() {
      if (!this.keywords) return;
      this.$router.push({
        name: "searchList",
        query: {
          keywords: this.keywords,
        },
      });
    },
    changSubject(id) {
      this.searchForm.fk_subject_id = id;
      this.initPage();
    },
    changNav(type, category) {
      this.searchForm.type = type;
      this.getCategory(category);
      Api.ResourceGrade({
        fk_business_id: this.$store.state.businessId,
        fk_category_id: type,
      }).then((res) => {
        this.classList = res.data || [];
      });
      Api.ResourceSubject({
        fk_business_id: this.$store.state.businessId,
        fk_category_id: type,
      }).then((res) => {
        this.subjectList = res.data || [];
      });
      this.initPage();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 50px;
  font-size: 15px;
  border: none;
}
::v-deep .el-input__suffix {
  .el-icon-circle-close:before {
    font-size: 20px;
    position: relative;
    top: 4px;
  }
}
.resourceSharing {
  width: 100%;
  background: url("../../assets/resourceSharing/banner.png") no-repeat;
  background-position: center top;
  background-color: #f5f5f5;
  .content-box {
    width: 1200px;
    margin: 0 auto;
    .search-box {
      padding-top: 30px;
      width: 603px;
      display: flex;
      flex-direction: column;
      .search-img {
        width: 60%;
        height: auto;
      }
      .search {
        width: 90%;
        height: 50px;
        display: flex;
        align-items: center;
        padding-top: 25px;
        .input-box {
          flex: 1;
          height: 100%;
          border-radius: 10px 0 0 10px;
          overflow: hidden;
        }
        .search-btn {
          width: 115px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #0086ff;
          color: #fff;
          font-size: 18px;
          border-radius: 0px 10px 10px 0px;
          cursor: pointer;
        }
      }
    }
  }
  .nav-box {
    width: 100%;
    height: 75px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 38px;
    z-index: 10;
    position: relative;
    .nav-list {
      background-color: #fff;
      width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10;
      .nav-item {
        width: 200px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        color: #000000;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          background-color: #eaeaea;
        }
        .item-icon {
          width: 30px;
          height: 30px;
          margin-right: 7px;
        }
        &.active {
          background: #0086ff;
          color: #fff;
        }
      }
    }
  }
  .content-box {
    ::v-deep .el-pager li {
      background: transparent;
    }
    ::v-deep .el-pagination .btn-next,
    .el-pagination .btn-prev {
      background: transparent;
    }
    .onLine {
      width: 1200px;
      margin: 0 auto;
      .title {
        font-size: 22px;

        font-weight: 400;
        color: #000000;
        padding-bottom: 22px;
        padding-top: 40px;
      }
      .nav-box1 {
        flex: 1;
        display: flex;
        flex-direction: column;
        .nav-type {
          display: flex;
          align-items: center;
          padding: 16px;
          border-top: 1px solid #bfbfbf;
          .type-title {
            font-size: 14px;

            font-weight: 400;
            color: #000000;
            padding-right: 40px;
          }
          .type-list {
            display: flex;
            align-items: center;
            .type-item {
              padding: 0 11px;
              height: 36px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: 400;
              color: #000000;
              margin-right: 24px;
              cursor: pointer;
              &:hover {
                background-color: #eaeaea;
              }
              &.active {
                color: #fff;
                background-color: #0086ff;
              }
            }
          }
        }
      }
      .kong {
        width: 100%;
        margin: 0 auto;
      }
      .pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 70px;
      }
    }
  }
}
</style>
